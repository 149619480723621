@import "../../styles/variables.scss";

.menu {
  top: 0;
  background-color: $green;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 100;
  margin: auto;
  &__hamburger {
    position: absolute;
    top: clamp(11px, 5.140079999999999vw, 44px);
    right: clamp(10px, 4.6728vw, 40px);
  }
  &__links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__logo {
    width: clamp(61px, 28.50408vw, 244px);
    height: clamp(49.5px, 23.13036vw, 198px);
    margin-bottom: clamp(7.5px, 3.5046vw, 30px);
  }
  &__item {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: clamp(15px, 7.0092vw, 60px);
    color: $white;
    text-align: center;
    margin-top: clamp(10px, 4.6728vw, 40px);
  }
}
