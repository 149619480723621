@import "../../styles/variables.scss";

.advant {
  margin: auto;
  margin-top: clamp(32px, 14.95296vw, 128px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  &__text {
    color: $white;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: clamp(9.5px, 4.439159999999999vw, 38px);
    width: 80%;
    position: absolute;
    top: clamp(30px, 14.0184vw, 120px);
    line-height: clamp(15.31px, 7.1540568vw, 61.24px);
  }
  &__text-s {
    color: $white;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: clamp(9.5px, 4.439159999999999vw, 38px);
    width: 80%;
    position: absolute;
    top: clamp(37.5px, 17.523vw, 150px);
    line-height: clamp(15.31px, 7.1540568vw, 61.24px);
  }

  &__img {
    width: clamp(195px, 91.11959999999999vw, 780px);
    height: clamp(107.23px, 50.1064344vw, 428.92px);
  }
}

@media screen and (min-width: 1024px) {
  .advant {
    position: relative;
    margin-top: clamp(32px, 3.33312vw, 128px);

    &__text {
      width: 70%;
      text-align: center;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: clamp(9.5px, 0.9895200000000001vw, 38px);
      line-height: clamp(13px, 1.35408vw, 52px);
      position: absolute;
      top: clamp(25px, 2.604vw, 100px);
    }
    &__text-s {
      width: 70%;
      text-align: center;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: clamp(9.5px, 0.9895200000000001vw, 38px);
      line-height: clamp(13px, 1.35408vw, 52px);
      position: absolute;
      top: clamp(30px, 3.1248vw, 120px);
      color: $white2;
    }

    &__img {
      width: clamp(200px, 20.832vw, 800px);
      height: clamp(90px, 9.3744vw, 360px);
    }
  }
}
