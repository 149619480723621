@import "../../../styles/variables.scss";

.simple {
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: clamp(16px, 7.47648vw, 64px);
  background-color: $white2;
  border: none;
  border: 3px solid $green;
  padding: clamp(7.5px, 3.5046vw, 30px);
  width: clamp(125px, 58.41vw, 500px);
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: clamp(10px, 4.6728vw, 40px);
  text-align: center;
  transition: 0.3s ease;
  color: $green;
}

.simple:hover {
  background-color: $green;
  color: $white2;
  cursor: pointer;
}

.outline {
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: clamp(32px, 14.95296vw, 128px);
  background-color: $green;
  border: none;
  border: 3px solid $white;
  padding: clamp(7.5px, 3.5046vw, 30px);
  width: clamp(125px, 58.41vw, 500px);
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: clamp(10px, 4.6728vw, 40px);
  text-align: center;
  transition: 0.3s ease;
  color: $white;
}

.outline:hover {
  background-color: $white;
  color: $green;
  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  .simple {
    width: clamp(130px, 13.5408vw, 520px);
    padding: clamp(7px, 0.72912vw, 28px);
    border: none;
    font-family: "Open Sans", sans-serif;
    font-size: clamp(10px, 1.0416vw, 40px);
    transition: 0.3s ease;
    border: 2px solid $green;
  }

  .simple:hover {
    background-color: $green;
    color: $white;
    cursor: pointer;
  }

  .outline {
    width: clamp(130px, 13.5408vw, 520px);
    padding: clamp(7.5px, 0.7812vw, 30px);
    font-family: "Open Sans", sans-serif;
    font-size: clamp(10px, 1.0416vw, 40px);
    margin-top: clamp(16px, 1.66656vw, 64px);
  }
}
