@import "../../styles/variables.scss";

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: clamp(32px, 14.95296vw, 128px);

  &__text {
    font-family: "Open Sans", sans-serif;
    line-height: clamp(12.895px, 6.0255756vw, 51.58px);
    color: $dark;
    text-align: center;
    font-size: clamp(8px, 3.73824vw, 32px);
    margin-bottom: clamp(5px, 2.3364vw, 20px);
  }

  &__input {
    text-align: center;
    padding: clamp(10px, 4.6728vw, 40px);
    width: clamp(150px, 70.092vw, 600px);
    border: 1px solid $white;
    margin-bottom: clamp(16px, 7.47648vw, 64px);
    font-family: "Open Sans", sans-serif;
    font-size: clamp(8px, 3.73824vw, 32px);
    line-height: clamp(12.895px, 6.0255756vw, 51.58px);
  }

  &__input-s {
    text-align: center;
    padding: clamp(10px, 4.6728vw, 40px);
    width: clamp(150px, 70.092vw, 600px);
    border: 1px solid $white;
    margin-bottom: clamp(16px, 7.47648vw, 64px);
    font-family: "Open Sans", sans-serif;
    font-size: clamp(8px, 3.73824vw, 32px);
    line-height: clamp(12.895px, 6.0255756vw, 51.58px);
  }

  &__error {
    color: red;
    font-family: "Open Sans", sans-serif;
    line-height: clamp(12.895px, 6.0255756vw, 51.58px);
    text-align: center;
    font-size: clamp(8px, 3.73824vw, 32px);
    margin-bottom: clamp(8px, 3.73824vw, 32px);
  }

  &__button {
    padding: clamp(7.5px, 3.5046vw, 30px);
    width: clamp(125px, 58.41vw, 500px);
    border: none;
    background-color: $green;
    color: $white;
    font-family: "Open Sans", sans-serif;
    font-size: clamp(9px, 4.20552vw, 36px);
    font-weight: 500;
    margin-top: clamp(8px, 3.73824vw, 32px);
  }
}

@media screen and (min-width: 1024px) {
  .container {
    position: relative;
  }

  .form {
    position: absolute;
    right: clamp(50px, 5.208vw, 200px);
    top: clamp(40px, 4.1664vw, 160px);
    width: clamp(410px, 42.705600000000004vw, 1640px);
    border-radius: 10px;
    background-color: $white;
    box-shadow: 0px 4px 42px 0px rgba(0, 0, 0, 0.15);
    height: auto;
    padding-bottom: clamp(16px, 1.66656vw, 64px);
    padding-top: clamp(16px, 1.66656vw, 64px);

    &__content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      flex-direction: row;
    }

    &__text {
      font-size: clamp(8px, 0.83328vw, 32px);
      text-align: left;
      margin-bottom: clamp(2px, 0.20832vw, 8px);
    }

    &__error {
      font-size: clamp(8px, 0.83328vw, 32px);
    }

    &__input {
      margin-bottom: clamp(2px, 0.20832vw, 8px);
      width: clamp(163.5px, 17.030160000000002vw, 654px);
      padding: 0;
      font-size: clamp(8px, 0.83328vw, 32px);
      text-align: left;
      text-indent: 15px;
    }
    &__card-s {
      margin-top: clamp(16px, 1.66656vw, 64px);
    }
    &__input-s {
      margin-bottom: clamp(2px, 0.20832vw, 8px);
      width: clamp(367.5px, 38.278800000000004vw, 1470px);
      padding: 0;
      font-size: clamp(8px, 0.83328vw, 32px);
      text-align: left;
      text-indent: 15px;
    }

    &__button {
      width: clamp(130px, 13.5408vw, 520px);
      padding: clamp(7.5px, 0.7812vw, 30px);
      font-family: "Open Sans", sans-serif;
      font-size: clamp(10px, 1.0416vw, 40px);
      transition: 0.3s all;
    }
    &__button:hover {
      cursor: pointer;
      background-color: #024d39;
    }
  }
}
