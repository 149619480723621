@import "../../../styles//variables.scss";

.main {
  display: flex;
  justify-content: center;
  flex-direction: column;

  &__content {
    width: 90%;
    font-family: "Rubik", sans-serif;
    font-weight: 700;
    margin: auto;
    margin-top: clamp(32px, 14.95296vw, 128px);
  }

  &__span {
    color: $green;
  }

  &__first {
    text-align: center;
    color: $dark;
    font-size: clamp(16px, 7.47648vw, 64px);
  }

  &__second {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: clamp(9px, 4.20552vw, 36px);
    text-align: center;
    line-height: clamp(13.5px, 6.30828vw, 54px);
    margin-top: clamp(16px, 7.47648vw, 64px);
  }

  &__train {
    width: clamp(214px, 99.99792vw, 856px);
    height: clamp(134px, 62.61552vw, 536px);
    margin-top: clamp(32px, 14.95296vw, 128px);
  }

  &__train-pc {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .main {
    &__content {
      width: 86.6%;
    }

    &__first {
      text-align: left;
      font-family: "Rubik", sans-serif;
      font-size: clamp(26px, 2.70816vw, 104px);
      width: 55%;
      font-weight: 700;
    }

    &__second {
      text-align: left;
      font-family: "Open Sans", sans-serif;
      font-size: clamp(10px, 1.0416vw, 40px);
      line-height: clamp(16.12px, 1.6790592000000002vw, 64.48px);
      width: 35%;
      margin-top: clamp(16px, 1.66656vw, 64px);
    }

    &__buttons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: clamp(12.5px, 1.302vw, 50px);
      margin-bottom: clamp(100px, 10.416vw, 400px);
    }

    &__train {
      display: none;
    }

    &__train-pc {
      display: flex;
      width: clamp(519.5px, 54.11112vw, 2078px);
      height: clamp(274.5px, 28.591920000000002vw, 1098px);
      position: absolute;
      left: clamp(440px, 45.830400000000004vw, 1760px);
      top: clamp(155px, 16.1448vw, 620px);
      z-index: -1;
    }
  }
}

@media screen and (min-width: 1440px) {
  .main {
    &__train-pc {
      position: absolute;
      bottom: clamp(160px, 16.6656vw, 640px);
    }
  }
}
