@import "../../styles/variables.scss";

.card {
  width: 90%;
  margin: auto;
  height: auto;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: clamp(16px, 7.47648vw, 64px);

  &__img {
    margin-top: clamp(16px, 7.47648vw, 64px);
    width: clamp(45px, 21.0276vw, 180px);
    height: clamp(45px, 21.0276vw, 180px);
  }
  &__img-f {
    margin-top: clamp(16px, 7.47648vw, 64px);
    width: clamp(45px, 21.0276vw, 180px);
    height: clamp(45px, 21.0276vw, 180px);
  }
  &__img-s {
    margin-top: clamp(16px, 7.47648vw, 64px);
    width: clamp(45px, 21.0276vw, 180px);
    height: clamp(45px, 21.0276vw, 180px);
  }
  &__img-t {
    margin-top: clamp(16px, 7.47648vw, 64px);
    width: clamp(45px, 21.0276vw, 180px);
    height: clamp(45px, 21.0276vw, 180px);
  }

  &__text {
    margin-top: clamp(8px, 3.73824vw, 32px);
    width: 70%;
  }

  &__main {
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: clamp(12px, 5.60736vw, 48px);
    font-weight: 500;
  }
  &__mainS {
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: clamp(12px, 5.60736vw, 48px);
    font-weight: 500;
    position: relative;
    right: clamp(17.5px, 8.177399999999999vw, 70px);
  }
  &__main_F {
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: clamp(12px, 5.60736vw, 48px);
    font-weight: 500;
  }
  &__mainL {
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: clamp(12px, 5.60736vw, 48px);
    font-weight: 500;
  }

  &__par {
    margin: auto;
    font-size: clamp(8.5px, 3.9718799999999996vw, 34px);
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    text-align: center;
    margin-top: clamp(16px, 7.47648vw, 64px);
    margin-bottom: clamp(16px, 7.47648vw, 64px);
  }
  &__par_f {
    margin: auto;
    font-size: clamp(8.5px, 3.9718799999999996vw, 34px);
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    text-align: center;
    margin-top: clamp(8px, 3.73824vw, 32px);
    margin-bottom: clamp(16px, 7.47648vw, 64px);
  }
  &__par_l {
    margin: auto;
    font-size: clamp(8.5px, 3.9718799999999996vw, 34px);
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    text-align: center;
    margin-top: clamp(8px, 3.73824vw, 32px);
    margin-bottom: clamp(16px, 7.47648vw, 64px);
  }
}

@media screen and (min-width: 1024px) {
  .card {
    width: clamp(200px, 20.832vw, 800px);
    height: clamp(237.5px, 24.738vw, 950px);
    margin-top: clamp(16px, 1.66656vw, 64px);
    padding-top: clamp(16px, 1.66656vw, 64px);
    padding-bottom: clamp(16px, 1.66656vw, 64px);
    &__img {
      margin-top: 1px;
      width: clamp(46.5px, 4.84344vw, 186px);
      height: clamp(46.5px, 4.84344vw, 186px);
      margin-right: clamp(107.5px, 11.1972vw, 430px);
      position: relative;
      bottom: clamp(12.5px, 1.302vw, 50px);
    }
    &__img-f {
      margin-top: 1px;
      width: clamp(46.5px, 4.84344vw, 186px);
      height: clamp(46.5px, 4.84344vw, 186px);
      margin-right: clamp(107.5px, 11.1972vw, 430px);
      position: relative;
      bottom: clamp(12px, 1.24992vw, 48px);
    }
    &__img-s {
      margin-top: 1px;
      width: clamp(46.5px, 4.84344vw, 186px);
      height: clamp(46.5px, 4.84344vw, 186px);
      margin-right: clamp(107.5px, 11.1972vw, 430px);
    }
    &__img-t {
      margin-top: 1px;
      width: clamp(46.5px, 4.84344vw, 186px);
      height: clamp(46.5px, 4.84344vw, 186px);
      margin-right: clamp(107.5px, 11.1972vw, 430px);
      position: relative;
      bottom: clamp(10px, 1.0416vw, 40px);
    }

    &__text {
      margin-top: clamp(8px, 0.83328vw, 32px);
      width: 60%;
      margin-right: clamp(25px, 2.604vw, 100px);
    }

    &__main {
      font-size: clamp(12px, 1.24992vw, 48px);
      text-align: left;
      width: 80%;
      line-height: clamp(19.34px, 2.0144544vw, 77.36px);
    }
    &__mainS {
      font-size: clamp(12px, 1.24992vw, 48px);
      position: relative;
      left: 0;
    }
    &__main_F {
      font-size: clamp(12px, 1.24992vw, 48px);
      text-align: left;
      width: 80%;
      line-height: clamp(19.34px, 2.0144544vw, 77.36px);
      font-family: "Rubik", sans-serif;
      font-weight: 500;
      position: relative;
      bottom: clamp(11px, 1.1457600000000001vw, 44px);
    }
    &__mainL {
      font-size: clamp(12px, 1.24992vw, 48px);
      text-align: left;
      width: 80%;
      line-height: clamp(19.34px, 2.0144544vw, 77.36px);
      font-family: "Rubik", sans-serif;
      font-weight: 500;
      position: relative;
      bottom: clamp(7.5px, 0.7812vw, 30px);
    }
    &__par {
      margin-top: clamp(8px, 0.83328vw, 32px);
      font-size: clamp(8.5px, 0.88536vw, 34px);
      text-align: left;
      margin-bottom: clamp(-2.5px, -0.2604vw, -10px);
      width: 120%;
    }
    &__par_f {
      margin-top: clamp(8px, 0.83328vw, 32px);
      font-size: clamp(8.5px, 0.88536vw, 34px);
      text-align: left;
      margin-bottom: clamp(-2.5px, -0.2604vw, -10px);
      width: 120%;
      position: relative;
      font-weight: 400;
      font-family: "Open Sans", sans-serif;
      position: relative;
      bottom: clamp(10px, 1.0416vw, 40px);
    }
    &__par_l {
      margin-top: clamp(8px, 0.83328vw, 32px);
      font-size: clamp(8.5px, 0.88536vw, 34px);
      text-align: left;
      margin-bottom: clamp(-2.5px, -0.2604vw, -10px);
      width: 120%;
      position: relative;
      font-weight: 400;
      font-family: "Open Sans", sans-serif;
      position: relative;
      bottom: clamp(5.5px, 0.5728800000000001vw, 22px);
    }
  }
}
