@import "../../styles/variables.scss";

.calc {
  margin: auto;
  width: 90%;
  background-color: $green;
  border-radius: 5px;
  padding-bottom: clamp(16px, 1.66656vw, 64px);

  &__text {
    color: $white;
    margin-top: clamp(16px, 1.66656vw, 64px);
    font-weight: 400;
    font-size: clamp(8px, 3.73824vw, 32px);
    font-family: "Open Sans", sans-serif;
    margin-bottom: clamp(5px, 2.3364vw, 20px);
    text-align: left;
  }

  &__inputs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__input {
    position: relative;
  }

  &__loc {
    position: absolute;
    width: clamp(12px, 5.60736vw, 48px);
    height: clamp(12px, 5.60736vw, 48px);
    bottom: clamp(9.5px, 4.439159999999999vw, 38px);
  }

  &__loc-c {
    position: absolute;
    width: clamp(9px, 4.20552vw, 36px);
    height: clamp(9px, 4.20552vw, 36px);
    bottom: clamp(10.5px, 4.90644vw, 42px);
    left: clamp(1.5px, 0.70092vw, 6px);
    z-index: 3;
  }

  &__item {
    padding: clamp(10px, 4.6728vw, 40px);
    width: clamp(155px, 72.4284vw, 620px);
    border: none;
    text-align: left;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: clamp(8px, 3.73824vw, 32px);
    text-indent: clamp(5px, 2.3364vw, 20px);
  }

  &__item-s {
    padding: clamp(10px, 4.6728vw, 40px);
    padding-left: clamp(32.5px, 15.186599999999999vw, 130px);
    padding-right: clamp(32.5px, 15.186599999999999vw, 130px);
    border: none;
    text-align: left;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: clamp(8px, 3.73824vw, 32px);
    text-indent: clamp(-45px, -21.0276vw, -180px);
  }

  &__sort {
    width: clamp(12px, 5.60736vw, 48px);
    height: clamp(12px, 5.60736vw, 48px);
    position: absolute;
    right: clamp(7.5px, 3.5046vw, 30px);
    bottom: clamp(8.5px, 3.9718799999999996vw, 34px);
  }

  &__button {
    background-color: $gold;
    border: none;
    padding: clamp(7.5px, 3.5046vw, 30px);
    width: clamp(125px, 58.41vw, 500px);
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: clamp(10px, 4.6728vw, 40px);
    text-align: center;
    margin-top: clamp(16px, 7.47648vw, 64px);
    margin-left: clamp(34px, 15.887519999999999vw, 136px);
    color: $dark;
  }
}

@media screen and (min-width: 1024px) {
  .calc {
    width: clamp(500px, 52.08vw, 2000px);
    height: auto;

    &__inputs {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      flex-direction: row;
      width: 80%;
      margin: auto;
    }

    &__input {
      padding-left: 10px;
    }

    &__text {
      font-size: clamp(8px, 0.83328vw, 32px);
      font-weight: 400;
    }

    &__item {
      font-size: clamp(8px, 0.83328vw, 32px);
      font-weight: 400;
      padding: clamp(10px, 1.0416vw, 40px);
      width: clamp(175px, 18.228vw, 700px);
      text-align: left;
      text-indent: clamp(10px, 1.0416vw, 40px);
    }

    &__item-s {
      font-size: clamp(8px, 0.83328vw, 32px);
      font-weight: 400;
      padding: clamp(10px, 1.0416vw, 40px);
      width: clamp(375px, 39.06vw, 1500px);
      text-align: left;
      text-indent: clamp(0.5px, 0.05208vw, 2px);
    }

    &__item-s::placeholder {}

    &__button {
      margin: auto;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: clamp(16px, 1.66656vw, 64px);
      background-color: $gold;
      border: none;
      width: clamp(130px, 13.5408vw, 520px);
      padding: clamp(6.5px, 0.67704vw, 26px);
      font-family: "Open Sans", sans-serif;
      font-size: clamp(10px, 1.0416vw, 40px);
      font-weight: 600;
      text-align: center;
      transition: 0.3s ease;
      color: $dark;
    }

    &__button:hover {
      cursor: pointer;
      background-color: $gold2;
    }

    &__loc {
      width: clamp(14px, 1.45824vw, 56px);
      height: clamp(13px, 1.35408vw, 52px);
      position: absolute;
      bottom: clamp(9px, 0.93744vw, 36px);

      left: clamp(11px, 1.1457600000000001vw, 44px);
    }

    &__loc-c {
      position: absolute;
      width: clamp(10px, 1.0416vw, 40px);
      height: clamp(10px, 1.0416vw, 40px);
      bottom: clamp(11px, 1.1457600000000001vw, 44px);
      left: clamp(11px, 1.1457600000000001vw, 44px);
      z-index: 3;
    }

    &__sort {
      width: clamp(12px, 1.24992vw, 48px);
      height: clamp(12px, 1.24992vw, 48px);
      position: absolute;
      bottom: clamp(9px, 0.93744vw, 36px);

      right: clamp(5px, 0.5208vw, 20px);
    }
  }
}